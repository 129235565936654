import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { EuiSpacer, EuiButton } from '@elastic/eui';
import SuccessBox from 'components/Web_User_Interface/1080p_Series/Multimedia/Image/SuccessBox';
import ImageTable from 'components/Web_User_Interface/1080p_Series/Multimedia/Image/imageTable';
import ImageAdvancedTable from 'components/Web_User_Interface/1080p_Series/Multimedia/Image/imageAdvancedTable';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "Multimedia Menu // Image Settings",
  "path": "/Web_User_Interface/1080p_Series/Multimedia/Image/",
  "dateChanged": "2017-12-11",
  "author": "Mike Polinowski",
  "excerpt": "Here you can adjust the image parameters brightness, saturation, contrast, exposure (shutter) and sharpness. You can flip and mirror the video image, can display a timestamp and set a camera name and display it as well. The displayed parameters are burnt into the camera image. Please make sure to restart the camera after changing the name.",
  "image": "./WebUI_1080p_SearchThumb_Multimedia_Image.png",
  "social": "/images/Search/WebUI_1080p_SearchThumb_Multimedia_Image.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_WebUI_white.webp",
  "chapter": "1080p Web User Interface"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">





    <SEOHelmet title='Multimedia Menu // Image Settings' dateChanged='2017-12-11' author='Mike Polinowski' tag='INSTAR IP Camera' description='Here you can adjust the image parameters brightness, saturation, contrast, exposure (shutter) and sharpness. You can flip and mirror the video image, can display a timestamp and set a camera name and display it as well. The displayed parameters are burnt into the camera image. Please make sure to restart the camera after changing the name.' image='/images/Search/WebUI_1080p_SearchThumb_Multimedia_Image.png' twitter='/images/Search/WebUI_1080p_SearchThumb_Multimedia_Image.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Web_User_Interface/1080p_Serie/Multimedia/Bild/' locationFR='/fr/Web_User_Interface/1080p_Series/Multimedia/Image/' crumbLabel="Image Settings" mdxType="BreadCrumbs" />
    <h1 {...{
      "id": "1080p-web-user-interface",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#1080p-web-user-interface",
        "aria-label": "1080p web user interface permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`1080p Web User Interface`}</h1>
    <h2 {...{
      "id": "multimedia-menu",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#multimedia-menu",
        "aria-label": "multimedia menu permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Multimedia Menu`}</h2>
    <EuiSpacer mdxType="EuiSpacer" />
    <SuccessBox mdxType="SuccessBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7799e19ca49fddd4d5a805bd25545333/8dd93/1080p_Settings_Multimedia_Image.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAC0klEQVQoz2WSS0hUURzGbxCEPRZpZiqZhJaaNYpW6lynQm3GKDXSRktyLNDBCB9z7znnvpx75xEZqJlBUBuhKCRclKtsoYItalMIQbRRNJ+T70ZHHe8/7swIgR9nc873/b7/OXAohFiMEMaY43iBQzal3do5ZO0cqm4btLQO3G0frGodqO0YvP/8S6P7pchhUWq2WKqTU1LPpKVTCGEGYYSxw+k0GS+Xmiv+rPgnZhfHPd7JZXVkdm1iwT+54FvwQ093t62iouFW5Z0r14vO03TyaYoQronVYLfbpacNRtNVAFDXV0DdAADwLW9sLi4v+QDga++bF/W1LbWCWFzJmS4U6zIonuMYRGwscjichaYCtr4WADY2/RCQb84zPjo+OjIDoH541d1wpbLNWvOkpv7aJUtaahbFYsIEXu1wukx5hrqqUgDw+zdDsNfr8SyMTc0BQFfH0/fMjWFH3vf2ynd2rj4/k2IQaWJC187R5xYUmAJwaPL84tLv2cXV1TUA6OvpqivKMGektJTE91uP9t1LoViEGRZjDXbradpoNALAlqqCqsGqqvq31GBX19vXR2KPJyWmHo6MSY4Jx1kRlI3FNhZjhFxOp57ONRUWAqyq3llV1ei/GzDlBe+MB3zrrY8eR0dEnjyRFB5+8MDesPz4MIoEhBByuVw5en2uweD9+WlluGdmenp+fu7bj7HBzyO/ej8u9Q80O5VDsVFxCcf27Q/bs3tXTjRFcQERQmRZvllWFhUTd7sw+0G5PiFFZziX+cxAt9DZRQX5Rou5vMlSajXTJRd1Z9NP6VLjExJDcJCXJMlut8siLwm8IIp2UZIFycYLmBABE3tzs/uhW3EoiqLIsuxQ5P9hTpIkh2ZoniZFsSuyUwnmtUOtOuRoW4rnueDiOCJKkpbYtoOJnbJvi2pktL/dyGAWE14QCM8TjhcEQRRFKdC1k5QkSRRFQsg/CTrGo0g1AN0AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7799e19ca49fddd4d5a805bd25545333/e4706/1080p_Settings_Multimedia_Image.avif 230w", "/en/static/7799e19ca49fddd4d5a805bd25545333/d1af7/1080p_Settings_Multimedia_Image.avif 460w", "/en/static/7799e19ca49fddd4d5a805bd25545333/7f308/1080p_Settings_Multimedia_Image.avif 920w", "/en/static/7799e19ca49fddd4d5a805bd25545333/19805/1080p_Settings_Multimedia_Image.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7799e19ca49fddd4d5a805bd25545333/a0b58/1080p_Settings_Multimedia_Image.webp 230w", "/en/static/7799e19ca49fddd4d5a805bd25545333/bc10c/1080p_Settings_Multimedia_Image.webp 460w", "/en/static/7799e19ca49fddd4d5a805bd25545333/966d8/1080p_Settings_Multimedia_Image.webp 920w", "/en/static/7799e19ca49fddd4d5a805bd25545333/61eb3/1080p_Settings_Multimedia_Image.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7799e19ca49fddd4d5a805bd25545333/81c8e/1080p_Settings_Multimedia_Image.png 230w", "/en/static/7799e19ca49fddd4d5a805bd25545333/08a84/1080p_Settings_Multimedia_Image.png 460w", "/en/static/7799e19ca49fddd4d5a805bd25545333/c0255/1080p_Settings_Multimedia_Image.png 920w", "/en/static/7799e19ca49fddd4d5a805bd25545333/8dd93/1080p_Settings_Multimedia_Image.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7799e19ca49fddd4d5a805bd25545333/c0255/1080p_Settings_Multimedia_Image.png",
              "alt": "Web User Interface - 1080p Series - Multimedia Image",
              "title": "Web User Interface - 1080p Series - Multimedia Image",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <Link to="/Web_User_Interface/1080p_Series/Multimedia/Image/9008vs5905/" mdxType="Link"><EuiButton fill mdxType="EuiButton">IN-9008 Full HD vs IN-5905 HD</EuiButton></Link>
    <Link to="/Web_User_Interface/1080p_Series/Multimedia/Image/Wide_Dynamic_Range/" mdxType="Link"><EuiButton fill mdxType="EuiButton">WDR Settings</EuiButton></Link>
    <EuiSpacer mdxType="EuiSpacer" />
    <ImageTable mdxType="ImageTable" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/8dd93/1080p_Settings_Multimedia_Image_Advanced.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAOCAIAAACgpqunAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACyUlEQVQoz2WSW0gUURjHJwjCLg9tF1C6CGWpbWlYlLkWpetuUZpdEENypVCrh9XdmXOZnWnnsmt5WQuDXiIqLWgRUukGQWwFQb1FLz0F3X2opHbddWecnS92xyDox3k55zu/8/8+OAxCHEYIY0wpH6DIJ13qGHrROfS8bTDmiTzzRGKtkVj75Wdnrr7qDl8TKBbE8x5PW0mpfUv5VgYhzCKMMFYU1e2qO97U/DUO7yaT77+nJ+Pmxx/alyn9w7efUynzXjTqa27uOtFy8kBj/Q6Ho2QzQwj1cVk5HA5VOXa73AcBQNNmTDMDc5imoQHA64k717ydvR0BoaGFuvc0lFUwPKUsIn4OKYq63+3kvB0AkEqlMhkDADKZjKZp6ZkZAJgYjnYdaBnsbL/c7j2011Nu38lwmLC5qRU15K7Zfbb1mAmQSMR1XbdyNU1LJBIAMDx0ZYI9+lapeXOpZTRIvbXbGBYRHzvX9q6qamed20o2DMOSdV1PJZMJHR6P3jjXUNFUUdp7uDDWufrJqVKGQ5jlMM7K4SqHw+3OysnpadM0rbYNIzOr67oJ12+P5K9aV1xkX7mioKTAhncuY/wc9nMYIxRSVYej2uVyAcCMbmRyQHZyQ5+dBYC+3r78ZSs2bii22ZYuWZhXW5jHkBwIoVAoVFlZ6com/zKTn6zkqTR8ikP68yT8TlyMDNjyl68pWrtocd6C+fN25TMMzUEIkWW5vr6+1ul8eTcSu6WOjY8/ffTw/sjYhavRmz39D/oHpH7p0Okj1Y37yrZv3VRmL1xfNCdbviiKgiBiQhHlfX4/5/dLmBMIh3jKCQHxvNjTE5YVWZZlSZIUWfpXpqIoKEq2qsiyqqqKqkrZvWKdyLIcDAalHJbP8Dy1FqVEEEVJkoJ/y9aN/wn+helms3+7m8UcJnwgQHieUD4QCAiCIObe+t/MTScQQv4AOvfT8GdJF50AAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/e4706/1080p_Settings_Multimedia_Image_Advanced.avif 230w", "/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/d1af7/1080p_Settings_Multimedia_Image_Advanced.avif 460w", "/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/7f308/1080p_Settings_Multimedia_Image_Advanced.avif 920w", "/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/19805/1080p_Settings_Multimedia_Image_Advanced.avif 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/a0b58/1080p_Settings_Multimedia_Image_Advanced.webp 230w", "/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/bc10c/1080p_Settings_Multimedia_Image_Advanced.webp 460w", "/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/966d8/1080p_Settings_Multimedia_Image_Advanced.webp 920w", "/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/61eb3/1080p_Settings_Multimedia_Image_Advanced.webp 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/81c8e/1080p_Settings_Multimedia_Image_Advanced.png 230w", "/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/08a84/1080p_Settings_Multimedia_Image_Advanced.png 460w", "/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/c0255/1080p_Settings_Multimedia_Image_Advanced.png 920w", "/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/8dd93/1080p_Settings_Multimedia_Image_Advanced.png 1369w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c87e44bb6ff729a8d7a6c5cd79c6c070/c0255/1080p_Settings_Multimedia_Image_Advanced.png",
              "alt": "Web User Interface - 1080p Series - Multimedia Image",
              "title": "Web User Interface - 1080p Series - Multimedia Image",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <EuiSpacer mdxType="EuiSpacer" />
    <ImageAdvancedTable mdxType="ImageAdvancedTable" />
    <EuiSpacer mdxType="EuiSpacer" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      